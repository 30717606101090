import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import paths from 'commons/paths';
import { useMyPage } from 'hooks';
import { MyTopEatsBoardTable } from '../../pages/MyPage/Community/TopEatsBoard';

const BOARD_COUNT_TO_SHOW = 5;

const TopEatsBoardSection = () => {
  const { topEatsBoards, topEatsBoardsIsValidating, setPage } = useMyPage({});

  useEffect(() => {
    setPage('1');
  }, []);

  if (topEatsBoardsIsValidating) return <></>;

  return (
    <div className="mypage_section">
      <div className="mypage_inner">
        <div className="mypage_top">
          <h3 className="mypage_top_title">작성한 게시글 - 회사 옆 맛집</h3>
          {(topEatsBoards?.totalAmount as number) > BOARD_COUNT_TO_SHOW ? (
            <Link to={`${paths.getPath('myTopEatsBoard')}`} className="mypage_top_link">
              모두 보기
            </Link>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="mypage_board list_table">
        <MyTopEatsBoardTable
          list={topEatsBoards?.mainTopEatsBoardList || []}
          count={topEatsBoards?.totalAmount as number}
          limit={BOARD_COUNT_TO_SHOW}
          ga_belong="마이페이지/작성한 게시글 - 회사 옆 맛집"
        />
      </div>
    </div>
  );
};

export default TopEatsBoardSection;
