import React, { useEffect, useState } from 'react';
import { LeafPoll } from 'react-leaf-polls';
import { Result } from 'react-leaf-polls/dist/types/result';
import { Theme } from 'react-leaf-polls/dist/types/theme';
import 'resource/css/react-leaf-polls.css';
import useSimpleSurveyDetail from '../../../hooks/simple_survey/useSimpleSurveyDetail';
import InnerHTML from 'dangerously-set-html-content';
import AlertPopup from '../../organisms/Popup/AlertPopup';
import { MutatorCallback } from 'swr/dist/types';
import { useLocation } from 'react-router-dom';
import useMediaSimpleSurveyDetail from '../../../hooks/simple_survey/useMediaSimpleSurveyDetail';

/**
 * React-leaf-polls 차트 인터페이스
 */
export interface MediaSimpleSurveyProps<T> {
  /** 차트 유형 */
  type?: 'multiple' | 'binary';
  /** 미디어 ID */
  mediaId: string;
  questionId: string;
  /** 질문 */
  question: string;
  /** 질문 번호 */
  questionNumber?: number;
  /** 간단 설문 데이터 */
  mediaSimpleSurveyData: Result[];
  /** 테마 커스텀 */
  customTheme?: Theme;
  imageUri?: string;
  onVote?: (item: Result, results: Result[]) => {};
  isVoted?: boolean;
  isVotedId?: number;
  /** 문항 가이드 문구 노출 여부 */
  displayGuideYn?: 'Y' | 'N';
  /** 문항 가이드 문구 내용 */
  guideText?: string;
  /** 문항 가이드 문구 노출 위치 */
  guideTextPosition?: 'TOP' | 'BOTTOM' | 'NONE';
  mutate?: (data?: T | Promise<T> | MutatorCallback<T>, shouldRevalidate?: boolean) => Promise<T | undefined>;
}

export interface MediaSimpleSurveyItemType {
  percentage: number;
  votes: number | undefined;
  id: number;
  text: string | undefined;
  key: string | undefined;
}

const MediaSimpleSurveyPoll = <T extends MediaDetailResDto>(props: MediaSimpleSurveyProps<T>) => {
  const location = useLocation();
  const { callAddMediaSimpleSurveyAnswer } = useMediaSimpleSurveyDetail();

  const [isAlertPopupOpen, setIsAlertPopupOpen] = useState<boolean>(false);
  const [alertPopupContents, setAlertPopContents] = useState<string>('');

  const themeData = {
    textColor: 'black',
    mainColor: '#be004e',
    backgroundColor: 'rgb(255,255,255)',
    alignment: 'center',
  } as Theme;

  const onVote = (e: any) => {
    const replyData = {
      mediaId: props.mediaId,
      questionId: props.questionId,
      itemId: e.key,
    };

    callAddMediaSimpleSurveyAnswer(replyData).then(res => {
      if (res.resultCode !== 200) {
        setIsAlertPopupOpen(true);
        setAlertPopContents(res.resultMessage || '설문 답변 등록에 실패했습니다.');
      }
      props.mutate && props.mutate();
    });
  };

  // react-leaf-polls 라이브러리 오류로 인해, 1명도 투표 인원이 없는 경우, 0% 가 아닌 NaN% 값이 노출되어 렌더링 이후 치환 작업 수행
  // issue-url: https://hnine.atlassian.net/browse/LGCC22-2801?focusedCommentId=52534&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-52534
  useEffect(() => {
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        // @ts-ignore
        if (mutation.type === 'childList' || mutation.type === 'subtree') {
          const elements = document.querySelectorAll('div._3gEzx');
          elements.forEach(element => {
            const span = element.querySelector('span');
            if (span && span.textContent === 'NaN%') {
              span.textContent = '0%';
            }
          });
        }
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    // Cleanup the observer on component unmount
    return () => {
      observer.disconnect();
    };
  }, [location.pathname]);

  return (
    <>
      <section className="detail_article_inner survey_content">
        {/* 문항 가이드 문구 - 상단 */}
        {props.displayGuideYn === 'Y' && !!props.guideText && props.guideTextPosition === 'TOP' && (
          <InnerHTML className="survey_notice_top" html={props.guideText || ''} />
        )}
        <div className="survey_title">
          {/*<div className="num">*/}
          {/*  {(props.questionNumber as number) < 10 ? `0${props.questionNumber}` : props.questionNumber}.*/}
          {/*</div>*/}
          <InnerHTML className="text" html={props.question || ''} />
        </div>
        <LeafPoll
          type={'multiple'}
          results={props.mediaSimpleSurveyData}
          theme={themeData}
          onVote={onVote}
          isVoted={props?.isVoted}
          isVotedId={props?.isVotedId}
        />
        {/* 문항 가이드 문구 - 하단 */}
        {props.displayGuideYn === 'Y' && !!props.guideText && props.guideTextPosition === 'BOTTOM' && (
          <InnerHTML className="survey_notice_bottom" html={props.guideText || ''} />
        )}
      </section>
      <br />

      <AlertPopup isOpen={isAlertPopupOpen} onClose={() => history.go(0)}>
        {alertPopupContents}
      </AlertPopup>
    </>
  );
};

export default React.memo(MediaSimpleSurveyPoll, (prev, next) => {
  return JSON.stringify(prev) === JSON.stringify(next);
});
