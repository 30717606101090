import { fetcher } from 'services';

const useMediaSimpleSurveyDetail = () => {
  return {
    callAddMediaSimpleSurveyAnswer(params: MediaSimpleSurveyAnswerReqDto) {
      return fetcher.media.addMediaSimpleSurveyAnswer(params);
    },
  };
};

export default useMediaSimpleSurveyDetail;
