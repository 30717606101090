import { useMemo, useEffect, useState } from 'react';
import useSWR from 'swr';
import { endpoints, fetcher } from 'services';
import useMyInfo from './useMyInfo';
import { isDetailsFor } from 'utils/utils';
import Paths from '../commons/paths';

const useNewsDetail = (newsId: string) => {
  const { info } = useMyInfo();
  const [detailDesc, setDetailDesc] = useState<string>('');
  const [videoElements, setVideoElements] = useState<Element[]>();

  const isNewsDetailPage = useMemo(
    () => isDetailsFor(Paths.getPath('newsList').replace('/list', '')),
    [location.pathname]
  );

  const { data: newsData, mutate } = useSWR<any>(
    info && isNewsDetailPage && newsId ? [`${endpoints.media.media}/${newsId}`, newsId] : null,
    url =>
      fetcher.media.getMediaDetail(url).then(res => {
        return res;
      })
  );

  const { data: relatedData } = useSWR<any>(
    info && isNewsDetailPage && newsId ? [`${endpoints.media.related}/${newsId}`, newsId] : null,
    url => fetcher.media.getRelatedMedia(url).then(res => res)
  );

  // As-Is 미디어 콘텐츠 script내 video 처리
  useEffect(() => {
    if (newsData?.editorContents) {
      const container = document.createElement('div');
      container.innerHTML = newsData?.editorContents as string;

      const videoEls = Array.from(container.querySelectorAll('.video-js'));

      videoEls.forEach((el, index) => {
        const parentEl = el.parentNode;
        const videoContainer = document.createElement('div');
        videoContainer.id = `video-container-${index}`;

        if (parentEl) {
          parentEl.replaceChild(videoContainer, el as Node);
        }
      });

      setVideoElements(videoEls);
      setDetailDesc(container.innerHTML);
    }
  }, [newsData?.editorContents]);

  return {
    newsData: newsData as MediaDetailResDto,
    detailDesc,
    videoElements,
    mutate,
    relatedData,
    likeNews: () => {
      fetcher.media.postMediaLike({ mediaId: newsId }).then(res => {
        if (res.resultCode === 200) mutate();
      });
    },
  };
};

export default useNewsDetail;
